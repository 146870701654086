import memoize from 'fast-memoize';
import { normalizeValue } from './strings/normalizeValue';
import parsePhoneNumber from 'libphonenumber-js';

/**
 * JSON stringify for 2 values are equal
 *
 * @param valueA
 * @param valueB
 */
function _equalJson<T>(valueA: T, valueB: T): boolean {
    return JSON.stringify(valueA) === JSON.stringify(valueB);
}
export const equalJson = memoize(_equalJson);

export function copyJson<T>(value: T): T {
    if (!value) {
        return undefined;
    }
    return JSON.parse(JSON.stringify(value));
}

export function formatPhoneNumber(value: string): string {
    const validated = normalizeValue(value);
    if (!validated) {
        return undefined;
    }

    const phoneNumber = parsePhoneNumber(value, 'US');
    return phoneNumber.formatNational();
}

export function getObject(data?: unknown): unknown {
    return data || {};
}
